<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo"><vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Odontodocs 2.0 </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="d-flex justify-content-end mb-3">
            <locale />
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">{{ $t('Bem_vindo_ao') }} Odontodocs!
              👋</b-card-title>
          </div>
          <b-alert variant="danger" if="errors" show>
            <div class="alert-body" v-for="(error, index) in errors" :key="index">
              {{ index | passwordFilter }} {{ error[0] }}
            </div>
          </b-alert>



          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false:null"
                    name="login-email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('Senha') }}</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>{{ $t('Esqueceu_a_sua_senha') }}</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Senha" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="login-password" v-model="senha" :state="errors.length > 0 ? false:null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> {{ $t('Lembrar_Me') }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click.stop.prevent="validationForm" :disabled="submit">
                <b-spinner v-if="submit" /> {{ $t('Entrar') }} </b-button>
            </b-form>
          </validation-observer>
          <b-alert show variant="info" class="info-box my-3">
            <div class="d-flex align-items-center">
              <feather-icon icon="InfoIcon" class="mr-2" size="18" />
              <span>
                {{ $t('login_info_text') }}
              </span>
            </div>
          </b-alert>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cookie from '../../services/cookie'
import { mapState } from 'vuex'
import i18n from "@/libs/i18n";

export default {

  components: {
    BSpinner,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Cookie,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      submit: false,
      status: '',
      senha: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      errors: [],
    }
  },
  computed: {
    ...mapState({
      errors_user: state => state.user.errors_user
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'senha' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },

  },
  methods: {
    validationForm() {
      this.submit = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {

          const payload = {
            'email': this.userEmail,
            'password': this.senha
          }

          this.$http('odontodocs/login', { params: payload }).then(res =>{

            this.submit = true
            Cookie.setToken(res.data.access_token, res.data.expires_in)
            Cookie.setLoginUniqueToken(res.data.confirmation_token)
            store.commit('user/STORE_USER', res.data.data)
            store.commit('user/STORE_USER_ERROR_LOGIN', false)
            
            this.$router.push({ name: 'home'})
          })
              .catch(error => {

                Cookie.deleteToken()

                if (error.message.includes('Network Error')) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Nãs foi possível conectar ao servidor, tente novamente mais tarde!',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  });
                }

                if(Number(error.response.status) === 422){
                  this.errors = error.response.data.errors
                }
                
                if(Number(error.response.status) === 401){
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t('erro_login_senha'),
                      icon: 'InfoIcon',
                      variant: 'danger',
                    },
                  })
                }

                this.submit = false

              }).finally( (res) => {
            this.submit = false
          })
        }
      })
    }
  },
  filters:{
    passwordFilter(text){

      if(text == 'password'){
        return 'Senha'
      }

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.info-box {
  border-left: 4px solid #00cfe8;
  background-color: rgba(0, 207, 232, 0.12);
  color: #00cfe8;
  padding: 1rem;
  border-radius: 0.358rem;
  margin: 1rem 0;
  font-size: 0.95rem;
  
  .dark-layout & {
    background-color: rgba(0, 207, 232, 0.12);
  }
  
}
</style>
